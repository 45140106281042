import React from 'react'
import Image from "next/image"
import { Box, Button, Stack, Typography, useMediaQuery } from '@mui/material'
import { useRouter } from 'next/router';
import BannerPartner from '@/components/cards/banner-partner';

type Props = {}

export default function index({}: Props) {

  return (
      <BannerPartner />
  )
}