import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { Box, Link } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ImgUrl } from "@/config";
import Image from "next/image";
import { ICampaignProps } from "@/interfaces/campaign";
import Cookies from "universal-cookie";
import { GetAdvertCampaign } from "@/api/campaign";
import { useState } from "react";
import Swal from "sweetalert2";

interface CampaignPopupDialogsProps {
  data: ICampaignProps;
}

interface DialogObject {
  id: number;
  isOpen: boolean;
  img:string
}

const CampaignPopupDialogs: React.FC<CampaignPopupDialogsProps> = () => {
  const [open, setOpen] = React.useState(false);
  
 
  
  const cookie = new Cookies();
  const token = cookie.get('token');
  const [Getadvert, setGetadvert] = useState<any>([]);
 
  
  const [dialogs, setDialogs] = useState<DialogObject[]>([]);
  const GetCampaign = async (token: string) => {
    const res = await GetAdvertCampaign(token);
    if (res?.success == true) {
      setGetadvert(res.data);
      const dataCampaign: number[] = cookie.get("popupCampaign") ? cookie.get("popupCampaign") as number[] : [];
      const defaultDialogs = res.data
        .filter((defaultDialogsID: any) => !dataCampaign.includes(defaultDialogsID.id))
        .map((defaultDialogsID:any) => ({
          id: defaultDialogsID.id,
          isOpen: true,
          img: defaultDialogsID.advert_value,
          url: defaultDialogsID.advert_url,
          utm: defaultDialogsID.advert_utm,

        }));
      // console.log(defaultDialogs);
        

      setDialogs(defaultDialogs)
    }
   
    

  };


 


  const handleToCampaign = (advert_url: any, advert_utm: any) => {
    if (advert_utm !== null) {
      const url = advert_url + advert_utm;
      window.open(url, "_blank");
    } else {
      window.open(advert_url, "_blank");
    }
  };




  const handleCloseDialog = (dialogId:any) => {
   
    
    setDialogs((prevDialogs:any) =>
      prevDialogs.map((dialog:any) =>
        dialog.id === dialogId ? { ...dialog, isOpen: false } : dialog
      )
    );

  
    const dataCampaign: number[] = cookie.get("popupCampaign") ? cookie.get("popupCampaign") as number[] : [];
    const mergedUniqueArray = Array.from(new Set([...dataCampaign, dialogId])); 
    cookie.set("popupCampaign", JSON.stringify(mergedUniqueArray));
  };
 


  React.useEffect(()=>{
    GetCampaign(token);
   
  },[])

 



  React.useEffect(()=>{

  }, [open])


  
 
  
  return (
   
    <React.Fragment>
      {dialogs.map((data: any) => (
        <>

          <Dialog
            key={data.id}
            aria-labelledby="customized-dialog-title"
            open={data.isOpen}
            sx={{
              cursor: "pointer",
            
            }}
            PaperProps={{
              sx: {
                backgroundColor: "transparent",
                boxShadow: "none",
                padding: 3,
                cursor: "pointer", overflow: "hidden"
              },
            }}
          >
            <IconButton
              aria-label="close"
              onClick={() => handleCloseDialog(data.id)}
              sx={{
                right: -10,
                top: -10,
                position: "absolute",
                color: "#ffffff",
              }}
            >
              <CloseIcon />
            </IconButton>
            <Box>
              <Link
                onClick={() => handleToCampaign(data.url, data.utm)}
              >
                <Image
                  src={data.img}
                  alt={"title"}
                  height={200}
                  width={200}
                  objectFit="contain"
                  layout="responsive"
                  priority
                  style={{ maxWidth: "100%" }}
                />
              </Link>
            </Box>
          </Dialog>
        </>
      ))}
    </React.Fragment>

  );
};
export default CampaignPopupDialogs;
