import React from 'react';
import { Skeleton, Box, Grid, Tab, Typography, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Tabs, { tabsClasses } from '@mui/material/Tabs';

const SkeletonHome = () => {
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ textAlign: 'center', mt: 0, }}>
        <Tabs
          variant="scrollable"
          ScrollButtonComponent={({ direction, onClick }) => (
            <IconButton onClick={onClick} disableFocusRipple disableRipple style={{ fontSize: '16px' }}>
              {direction === 'left' ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faChevronRight} />}
            </IconButton>
          )}
          sx={{
            backgroundColor: '#ddd',
            display: 'inline-flex',
            borderRadius: '50px',
            position: 'relative',
            top: '20px',
            minHeight: '40px',
          }}
        >
          {/* Skeleton Tab */}
          <Tab
            label={
              <Skeleton variant="text" width={120} />
            }
          />
          {/* Skeleton Tab */}
          <Tab
            label={
              <Skeleton variant="text" width={120} />
            }
          />
        </Tabs>
      </Box>
      <TabPanelSkeleton />
    </Box>
  );
};

const TabPanelSkeleton = () => {
  return (
    <>
      <TabPanel />
      <Skeleton variant="rectangular" width={"100%"} height={400} />
    </>
  );
};

const TabPanel = () => {
  return (
    <>
      <Box sx={{
        flexGrow: 1, py: 3, border: "1px solid #ddd",
        margin: "0 0 30px",
        borderRadius: "10px"
      }} component="div">
        <Box
          sx={{
            flexGrow: 1,
            maxWidth: { xs: '100%', sm: '80%' },
            bgcolor: 'background.paper',
            margin: 'auto',
          }}
        >
          <Tabs
            variant="scrollable"
            ScrollButtonComponent={({ direction, onClick }) => (
              <IconButton onClick={onClick} disableFocusRipple disableRipple style={{ fontSize: '16px' }}>
                {direction === 'left' ? <FontAwesomeIcon icon={faChevronLeft} /> : <FontAwesomeIcon icon={faChevronRight} />}
              </IconButton>
            )}
            aria-label="visible arrows tabs example"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 0.3 },
              },
            }}
          >
            {Array.from({ length: 6 }).map((_, index) => (
              <Tab
                sx={{
                  padding: { xs: "5px 25px", sm: "20px 40px" }
                }}
                label={
                  <>
                    <Skeleton animation="wave" variant="circular" width={80} height={80} />
                    <Skeleton sx={{ my: 2 }} variant="text" width={100} height={20} />
                  </>
                }
              />
            ))}

          </Tabs>
        </Box>
      </Box>
    </>
  );
};

export default SkeletonHome;
