import Skeleton from '@mui/material/Skeleton';
import Grid from "@mui/material/Unstable_Grid2";

export default function SkeletonCard() {
  
  return (
    <Grid container md={12}>
      {Array.from({ length: 8 }).map((_, index) => (
        <Grid xs={6} md={3} key={index} sx={{ px: 1 }}>
          <Skeleton variant="rectangular" width="100%" sx={{ p: 0, borderRadius: "3px" }}>
            <div style={{ height: "204px" }} />
          </Skeleton>
          <Skeleton variant="rectangular" width="100%" height="20px" sx={{ my: 1, borderRadius: "3px" }} />
          <Skeleton variant="rectangular" width="100%" height="30px" sx={{ my: 1, borderRadius: "3px" }} />
        </Grid>
      ))}
    </Grid>
  );
}
