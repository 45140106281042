import Producttype from "@/components/cards/product-type";
import Bannerhome from "@/components/cards/banner-home";
import ProductCampaign from "@/components/cards/product-campaign";
import ProductService from "@/components/cards/product-service";
import Productrecommend from "@/components/cards/product-recommend";
import Article from "@/components/cards/article";
import { BreadcrumbJsonLd, DefaultSeo } from "next-seo";


import * as ApiProduct from "../api/product";
import * as ApiArticle from "../api/article";
import { BannerHome } from "../api/petsploy-center";
import ProductHomepage from "../components/cards/product-homepage";
import * as Campaign from "../api/campaign";
import { GetServerSideProps } from "next";
import { useEffect, useState } from "react";
import SkeletonHome from "@/components/skeleton/home";
import BannerCampaign from "@/components/cards/banner-campaign-top";
import CampaignPopupDialogs from "@/components/dialog/campaigmPopUp";
import { ICampaignProps } from "@/interfaces/campaign";
import Cookies from "universal-cookie";
import { ProducHomeBestSell, ProducHomeRecommend } from "../api/product";
import Activity from "@/components/cards/activity";
import TabCategoryProduct from "@/components/searchAll/TabCategoryProduct";
import { GetBannerAds } from "@/api/Banner/banner";
import Pathner from "./partner";
import { Config, isProduction } from "@/config";
import ComingSoonCampaign from "../components/campaign/campaigncomingsoon"
import { GetCampaignComingSoon } from "@/api/campaign/campaign";
import Script from "next/script";
import SkeletonCard from "@/components/skeleton/card";
import { seoConfig } from "@/config/seo.config";
import { MetaTag } from "next-seo/lib/types";


export default function Home({
  dataProductHome, dataRecommend, dataArticle, dataProductType,
  dataBannerHome, dataCampaignHalloween, getDataCampaignHalloween,
  dataCampaign, dataActivity, dataBannerAds, dataProductNew, datacampaignComingSoon }: any) {
 
  const [isLoading, setIsLoading] = useState(true);
  const currentUrl = typeof window !== "undefined" ? window.location.href : Config.maaBoom;

  useEffect(() => {
    const loadData = async () => {
    
      if (
        dataProductHome &&
        dataRecommend &&
        dataActivity &&
        dataArticle &&
        dataBannerAds &&
        dataProductType &&
        dataBannerHome &&
        dataProductNew
      ) {
        setIsLoading(false);
      }
    };

    loadData();
  }, [
    dataProductHome,
    dataRecommend,
    dataActivity,
    dataArticle,
    dataBannerAds,
    dataProductType,
    dataBannerHome,
    dataProductNew,
  ]);

   

 

  return (
    <>

      {isLoading ? (
        <SkeletonHome />
      ) : (
        <>
          <DefaultSeo
              title={seoConfig.title}
              titleTemplate={seoConfig.titleTemplate}
              defaultTitle={seoConfig.defaultTitle}
              description={seoConfig.description}
              canonical={currentUrl}
              openGraph={{
                ...seoConfig.openGraph,
                url: currentUrl,
                title: seoConfig.title,
              }}
              norobots={true}
              additionalMetaTags={[
                ...seoConfig.additionalMetaTags,
                isProduction ? seoConfig.robots.isProduction : seoConfig.robots.isNotProduction,
              ] as MetaTag[]}
              twitter={seoConfig.twitter}
              facebook={seoConfig.facebook}
            />

            <BreadcrumbJsonLd
              itemListElements={[
                {
                  position: 1,
                  name: 'หน้าแรก',
                  item: currentUrl,
                },

              ]}
            />

            <Script id="gtm-script-tag" strategy="afterInteractive">
              {`window.dataLayer = window.dataLayer || [];`}
            </Script>

          
            <TabCategoryProduct />

            {dataCampaign && <BannerCampaign data={dataCampaign} />}
            {dataBannerHome ? (
              <Bannerhome Bannerhome={dataBannerHome} />
            ) : (
              <SkeletonCard />
            )}
           
            <Producttype dataSet={dataProductType}></Producttype>

            {getDataCampaignHalloween?.data?.length > 0 ? (
              <ProductCampaign
                data={dataCampaignHalloween.products}
                dataBannerAds={dataBannerAds}
              />
            ) : (
              ""
            )}

            <ComingSoonCampaign datacampaignComingSoon={datacampaignComingSoon} dataBannerAds={dataBannerAds} />

            <ProductCampaign data={dataCampaign} dataBannerAds={dataBannerAds} />

           
          
            {dataProductNew ? (
              <ProductService dataSet={dataProductNew} />
            ) : (
              <SkeletonCard />
            )}

          
            <Article dataSet={dataArticle}></Article>

            <CampaignPopupDialogs data={dataCampaign} />


           
            <ProductHomepage
              dataSet={dataProductHome}
              pagination={true}
              dataBannerAds={dataBannerAds}
            ></ProductHomepage>

            <Productrecommend
              dataBannerAds={dataBannerAds}
            />

           
            <Activity
              dataActivity={dataActivity}
            />
            <Pathner />

        </>
      )}
    
    </>
  );
}


export const getServerSideProps: GetServerSideProps = async (context) => {
  const limitBestSell = "4";
  const limitHomepage = "12";
  const limitMayLike = "12";
  const producType = "Product";
  const authToken: any = context.req.cookies.token;

  try {
    const [
      resProductGroup,
      resProductType,
      resProductBestsellInfo,
      resProductHome,
      resProductRec,
      getCampaignHalloween,
      resArticle,
      resActivity,
      getCurrentCampaign,
      resBannerHome,
      resBannerAds,
      responseProductNew,
      responseCampaignComingSoon,
    ] = await Promise.all([
      ApiProduct.ProductGroup(),
      ApiProduct.ProductType(authToken),
      ApiProduct.ProducHomeBestSell(limitBestSell, producType),
      ApiProduct.ProducHomePage(limitHomepage),
      ApiProduct.ProducHomeRecommend(producType, authToken, limitMayLike),
      Campaign.GetCampaign(authToken),
      ApiArticle.Article(authToken),
      ApiArticle.ActivityDetail(authToken),
      Campaign.getCurrentCampaign(),
      BannerHome(),
      GetBannerAds(authToken),
      ApiProduct.GetProducts(),
      GetCampaignComingSoon(),
    ]);

    let resProductCampaignHalloween = null;
    if (getCampaignHalloween?.data?.data?.length > 0) {
      resProductCampaignHalloween = await Campaign.GetCampaignProduct(
        authToken,
        getCampaignHalloween.data.data[0].campaign_id,
        4,
        "random"
      );
    }

    if (
      resProductRec?.status === false ||
      resProductBestsellInfo?.status === false ||
      resArticle?.status === false ||
      resProductType?.status === false ||
      resProductGroup?.status === false ||
      resActivity?.status === false
    ) {
      return {
        redirect: {
          destination: "/404",
          permanent: false,
        },
      };
    }

    const dataProductGroup = resProductGroup?.data || null;
    const dataProductType = resProductType?.data || null;
    const dataBestSell = resProductBestsellInfo?.data || null;
    const dataProductHome = resProductHome?.data || null;
    const dataRecommend = resProductRec?.data || null;
    const dataArticle = resArticle || null;
    const dataBannerHome = resBannerHome?.data || null;
    const dataActivity = resActivity || null;
    const dataBannerAds = resBannerAds?.data || null;

    let dataCampaignHalloween = null;
    if (resProductCampaignHalloween !== null) {
      dataCampaignHalloween = resProductCampaignHalloween.data.data || null;
    }

    const getDataCampaignHalloween = getCampaignHalloween?.data || null;
    const dataCampaign = getCurrentCampaign?.data || null;

    const dataProductNew =
      responseProductNew.success === true ? responseProductNew.data : [];
    const datacampaignComingSoon =
      responseCampaignComingSoon.success === true
        ? responseCampaignComingSoon.data
        : [];

    return {
      props: {
        dataProductGroup,
        dataProductType,
        dataBestSell,
        dataProductNew,
        dataProductHome,
        dataRecommend,
        dataArticle,
        dataBannerHome,
        dataCampaignHalloween,
        getDataCampaignHalloween,
        dataCampaign,
        dataActivity,
        dataBannerAds,
        datacampaignComingSoon,
      },
    };
  } catch (error) {
    if (!isProduction) {
      console.error("Error in getServerSideProps:", error);
    }
    return {
      redirect: {
        destination: "/404",
        permanent: false,
      },
    };
  }
};