import React from "react";
import { Box, useMediaQuery } from "@mui/material";
import Image from "next/image";
import { ICampaignProps } from "@/interfaces/campaign";
import { ImgUrl } from "@/config";
import { useRouter } from "next/router";

interface BannerCampaignTopProps {
  data: ICampaignProps;
}
const BannerCampaignTop: React.FC<BannerCampaignTopProps> = ({ data }) => {
  const router = useRouter();
  const isMobile = useMediaQuery("(max-width:600px)");


  return (
    <>
    </>
   
    // <Box sx={{ position: "relative", width: "100%" }}>
    //   <Image
    //     src={
    //       isMobile
    //         ? `${ImgUrl?.imageCampaign}/uploads/campaign/${data?.id}/sub_banner_mobile/${data?.sub_banner_mobile}`
    //         : `${ImgUrl?.imageCampaign}/uploads/campaign/${data?.id}/sub_banner_desktop/${data?.sub_banner_desktop}`
    //     }
       
    //     alt={"banner-img"}
    //     layout="responsive"
    //     objectFit="contain"
    //     height={40}
    //     width={1}
    //     priority
    //     style={{ maxWidth: "100%", height: "auto" ,borderRadius:"14px"}}
    //   />
    // </Box>



  );
};
export default BannerCampaignTop;
